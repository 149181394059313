import React from 'react';
import { Container, Row, Col, Card, CardTitle, CardBody } from 'reactstrap';
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import locationMarker from "../../assets/images/icons/location_marker.png";

const mapStyles = {
    width: '100%',
    height: '100%',
    
};

const LoadingContainer = () => <div>Loading...</div>

const GoogleMap = (props) => {
    document.title = "Explore Map | Fish-Trak - Partner's Dashboard";
    return (
        <React.Fragment>

                <div className="page-content">

                                        <div id="gmaps-markers" className="gmaps" style={{ position: "relative" }}>

                                            <Map
                                                google={props.google}
                                                zoom={8}
                                                style={mapStyles}
                                                initialCenter={{ lat: 34.134117, lng: -118.321495 }}
                                            >
                                                <Marker 
                                                    name={'Your position'}
                                                    position={{ lat: 48.00, lng: -122.00 }} 
                                                    icon={{
                                                      url: locationMarker,
                                                      anchor: new props.google.maps.Point(32,32),
                                                      scaledSize: new props.google.maps.Size(64,64)
                                                    }}
                                                />
                                                <Marker 
                                                    position={{ lat: 34.134117, lng: -118.321495 }} 
                                                    icon={{
                                                      url: locationMarker,
                                                      anchor: new props.google.maps.Point(32,32),
                                                      scaledSize: new props.google.maps.Size(64,64)
                                                    }}
                                                />
                                                <Marker 
                                                    position={{ lat: 32.995049, lng: -111.536324 }} 
                                                    icon={{
                                                      url: locationMarker,
                                                      anchor: new props.google.maps.Point(32,32),
                                                      scaledSize: new props.google.maps.Size(64,64)
                                                    }}
                                                />
                                                <Marker 
                                                    position={{ lat: 37.383064, lng: -109.071236 }} 
                                                    icon={{
                                                      url: locationMarker,
                                                      anchor: new props.google.maps.Point(32,32),
                                                      scaledSize: new props.google.maps.Size(64,64)
                                                    }}
                                                />
                                                <Marker 
                                                    position={{ lat: 39.877586, lng: -79.640617 }} 
                                                    icon={{
                                                      url: locationMarker,
                                                      anchor: new props.google.maps.Point(32,32),
                                                      scaledSize: new props.google.maps.Size(64,64)
                                                    }}
                                                />
                                            </Map>

                                        </div>

                </div>
        </React.Fragment>
    );
}

export default (
    GoogleApiWrapper({
        apiKey: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
        LoadingContainer: LoadingContainer,
        v: "3",
    })(GoogleMap)
)




