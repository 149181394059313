import React, { useRef, useState, useEffect } from 'react'
import { Button, Card, CardBody, CardTitle, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, ModalHeader } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import List from 'list.js';
import Flatpickr from "react-flatpickr";
import { ThreeCircles } from 'react-loader-spinner'

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import {serverTimestamp} from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, uploadBytes, getStorage, ref } from "firebase/storage";


const AllCatches = () => {
    
    const storage = getStorage();
    
    const [initialLoad, setInitialLoad] = useState(true);
    const [allCatchesLedger, setAllCatchesLedger] = useState([]);
    const [fetchedData, setFetchedData] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [allSpecies, setAllSpecies] = useState([]);
    const [allTagPrograms, setAllTagPrograms] = useState([]);
    
    //Action buttons
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateCatchId, setUpdateCatchId] = useState('');
    const [updateCatchIndex, setUpdateCatchIndex] = useState(null);
    const [updateTagProgramId, setUpdateTagProgramId] = useState('');
    const [deleteCatchId, setDeleteCatchId] = useState('');
    const [deleteCatchIndex, setDeleteCatchIndex] = useState(null);
    const [isDeletingRecatch, setIsDeletingRecatch] = useState(false);
    const [isDeletingCatch, setIsDeletingCatch] = useState(false);
    const [isRestoringRecatch, setIsRestoringRecatch] = useState(false);
    const [isRestoringCatch, setIsRestoringCatch] = useState(false);
    const [restoreCatchId, setRestoreCatchId] = useState('');
    const [restoreCatchIndex, setRestoreCatchIndex] = useState(null);
    const [updatedImage, setUpdatedImage] = useState(null);
    
    //Session data references
    const userData = JSON.parse(localStorage.getItem("userData"));
    const programData = JSON.parse(localStorage.getItem("programData"));
    
    document.title = "Catch Ledger | Fish-Trak - Partner's Dashboard";

    const [modal_list, setmodal_list] = useState(false);
    function tog_list() {
        setmodal_list(!modal_list);
    }
    
    const [deleteRecatchModal, setDeleteRecatchModal] = useState(false);
    function toggleDeleteRecatchModal(catchId, catchIndex) {
        
        if(deleteRecatchModal) {
            setDeleteCatchId('');
            setDeleteCatchIndex(null);
        }
        else {
            setDeleteCatchId(catchId);
            setDeleteCatchIndex(catchIndex);
        }
        
        setDeleteRecatchModal(!deleteRecatchModal);
        
    }
    
    const [deleteCatchModal, setDeleteCatchModal] = useState(false);
    function toggleDeleteCatchModal(catchId, catchIndex) {
        
        if(deleteCatchModal) {
            setDeleteCatchId('');
            setDeleteCatchIndex(null);
        }
        else {
            setDeleteCatchId(catchId);
            setDeleteCatchIndex(catchIndex);
        }
        
        setDeleteCatchModal(!deleteCatchModal);
        
    }
    
    const [restoreRecatchModal, setRestoreRecatchModal] = useState(false);
    function toggleRestoreRecatchModal(catchId, catchIndex) {
        
        if(restoreRecatchModal) {
            setRestoreCatchId('');
            setRestoreCatchIndex(null);
        }
        else {
            setRestoreCatchId(catchId);
            setRestoreCatchIndex(catchIndex);
        }
        
        setRestoreRecatchModal(!restoreRecatchModal);
        
    }
    
    const [restoreCatchModal, setRestoreCatchModal] = useState(false);
    function toggleRestoreCatchModal(catchId, catchIndex) {
        
        if(restoreCatchModal) {
            setRestoreCatchId('');
            setRestoreCatchIndex(null);
        }
        else {
            setRestoreCatchId(catchId);
            setRestoreCatchIndex(catchIndex);
        }
        
        setRestoreCatchModal(!restoreCatchModal);
        
    }
    
    const deleteCatch = () => {
        
        setIsDeletingCatch(true);
        
        const usersRef = firebase.firestore().collection('users');
        const tagRef = firebase.firestore().collection('tags').doc(allCatchesLedger[deleteCatchIndex].tag.id);
        const catchesRef = firebase.firestore().collection('catches');
        
        //Get all catches using the same tag
        catchesRef
         .where('tag', '==', tagRef)
         .onSnapshot(
              querySnapshot => {
                  querySnapshot.forEach(doc => {
                      const catchItem = doc.data();
                      
                      //For each catch decrement user catch/recatch count
                      const userData = {
                        updated_at: serverTimestamp(),
                      };
                      if(catchItem.recatch) {                        
                          if(!catchItem.recatch_count || catchItem.recatch_count == 0 || catchItem.recatch_count == 1 || catchItem.recatch_count == '' || catchItem.recatch_count == null) {
                              userData['recatch_count'] = 0; 
                            }
                            else {
                                userData['recatch_count'] = firebase.firestore.FieldValue.increment(-1);
                            }
                        }  
                        else {
                            if(!catchItem.catch_count || catchItem.catch_count == 0 || catchItem.catch_count == 1 || catchItem.catch_count == '' || catchItem.catch_count == null) {
                              userData['catch_count'] = 0; 
                            }
                            else {
                                userData['catch_count'] = firebase.firestore.FieldValue.increment(-1);
                            }
                        }
                      
                      usersRef
                      .doc(catchItem.user.id)
                      .update(userData)
                      .then((userResponse) => {
                          
                          //Delete the catch or recatch
                          catchesRef
                            .doc(doc.id)
                            .update({
                              updated_at: serverTimestamp(),
                              deleted: true
                            })
                            .then(() => {
                                
                                console.log('Deleted catch');
                               
                            })
                            .catch((error) => {
                              setIsDeletingCatch(false);
                              alert('Could not delete catch entry. Please try again.');
                            });
                          
                        
                      })
                      .catch((error) => {
                        setIsDeletingCatch(false);
                        alert('Could not update user status. Please try again.');
                      }); 
                      
                  });
                  
                  tagRef
                    .update({
                      updated_at: serverTimestamp(),
                      deleted: true
                    })
                    .then(() => {
                        
                        fetchAllCatchesLedger();
                        console.log('The catch has been deleted successfully!');
                        setDeleteCatchId('');
                        setDeleteCatchIndex(null);
                       
                    })
                    .catch((error) => {
                      setIsDeletingCatch(false);
                      alert('Could not delete fish tag entry. Please try again.');
                    });
                  
              },
              error => {
                  console.log(error);
              }
          );   
          
    }
    
    const restoreCatch = () => {
        
        setIsRestoringCatch(true);
        
        const usersRef = firebase.firestore().collection('users');
        const tagRef = firebase.firestore().collection('tags').doc(allCatchesLedger[restoreCatchIndex].tag.id);
        const catchesRef = firebase.firestore().collection('catches');
        
        //Get all catches using the same tag
        catchesRef
         .where('tag', '==', tagRef)
         .onSnapshot(
              querySnapshot => {
                  querySnapshot.forEach(doc => {
                      const catchItem = doc.data();
                      
                      //For each catch decrement user catch/recatch count
                      const userData = {
                        updated_at: serverTimestamp(),
                      };
                      if(catchItem.recatch) {                        
                            userData['recatch_count'] = firebase.firestore.FieldValue.increment(1);
                        }  
                        else {
                            userData['catch_count'] = firebase.firestore.FieldValue.increment(1);
                        }
                      
                      usersRef
                      .doc(catchItem.user.id)
                      .update(userData)
                      .then((userResponse) => {
                          
                          //Delete the catch or recatch
                          catchesRef
                            .doc(doc.id)
                            .update({
                              updated_at: serverTimestamp(),
                              deleted: false
                            })
                            .then(() => {
                                
                                console.log('Restored catch');
                               
                            })
                            .catch((error) => {
                              setIsRestoringCatch(false);
                              alert('Could not restore catch entry. Please try again.');
                            });
                          
                        
                      })
                      .catch((error) => {
                        setIsRestoringCatch(false);
                        alert('Could not update user status. Please try again.');
                      }); 
                      
                  });
                  
                  tagRef
                    .update({
                      updated_at: serverTimestamp(),
                      deleted: false
                    })
                    .then(() => {
                        
                        fetchAllCatchesLedger();
                        console.log('The catch has been restored successfully!');
                        setRestoreCatchId('');
                        setRestoreCatchIndex(null);
                       
                    })
                    .catch((error) => {
                      setIsRestoringCatch(false);
                      alert('Could not restore fish tag entry. Please try again.');
                    });
                  
              },
              error => {
                  console.log(error);
              }
          );   
          
    }
        
    
    const deleteRecatch = async () => {
        
        setIsDeletingRecatch(true);
                
        const userRef = firebase.firestore().collection('users').doc(allCatchesLedger[deleteCatchIndex].user.id);
        const tagRef = firebase.firestore().collection('tags').doc(allCatchesLedger[deleteCatchIndex].tag.id);
        const catchRef = firebase.firestore().collection('catches').doc(deleteCatchId);  
        
        if(allCatchesLedger[deleteCatchIndex].recatch) {
            catchRef
              .update({
                updated_at: serverTimestamp(),
                deleted: true
              })
              .then(() => {
                tagRef
                    .update({
                        updated_at: serverTimestamp(),
                        catch_count: firebase.firestore.FieldValue.increment(-1),
                        latest_anglers: firebase.firestore.FieldValue.arrayRemove(userRef)
                      })
                    .then((tagResponse) => {
                        
                        userRef
                        .update({
                            updated_at: serverTimestamp(),
                            recatch_count: firebase.firestore.FieldValue.increment(-1)
                          })
                        .then((userResponse) => {
                            
                          fetchAllCatchesLedger();
                          alert('The recatch has been deleted successfully!');
                          setDeleteCatchId('');
                          setDeleteCatchIndex(null);
                          
                        })
                        .catch((error) => {
                          setIsDeletingRecatch(false);
                          alert('Could not update user status. Please try again.');
                        }); 

                      
                    })
                    .catch((error) => {
                      setIsDeletingRecatch(false);
                      alert('Could not update tag status. Please try again.');
                    });  
                    
              })
              .catch((error) => {
                setIsDeletingRecatch(false);
                alert('Could not delete recatch entry. Please try again.');
              });
        }
            
    };
    
    const restoreRecatch = async () => {
        
        setIsRestoringRecatch(true);
                
        const userRef = firebase.firestore().collection('users').doc(allCatchesLedger[restoreCatchIndex].user.id);
        const tagRef = firebase.firestore().collection('tags').doc(allCatchesLedger[restoreCatchIndex].tag.id);
        const catchRef = firebase.firestore().collection('catches').doc(restoreCatchId);  
        
        if(allCatchesLedger[restoreCatchIndex].recatch) {
            catchRef
              .update({
                updated_at: serverTimestamp(),
                deleted: false
              })
              .then(() => {
                tagRef
                    .update({
                        updated_at: serverTimestamp(),
                        catch_count: firebase.firestore.FieldValue.increment(1),
                        latest_anglers: firebase.firestore.FieldValue.arrayUnion(userRef)
                      })
                    .then((tagResponse) => {
                        
                        userRef
                        .update({
                            updated_at: serverTimestamp(),
                            recatch_count: firebase.firestore.FieldValue.increment(1)
                          })
                        .then((userResponse) => {
                            
                          fetchAllCatchesLedger();
                          alert('The recatch has been restored successfully!');
                          setRestoreCatchId('');
                          setRestoreCatchIndex(null);
                          
                        })
                        .catch((error) => {
                          setIsRestoringRecatch(false);
                          alert('Could not update user status. Please try again.');
                        }); 
    
                      
                    })
                    .catch((error) => {
                      setIsRestoringRecatch(false);
                      alert('Could not update tag status. Please try again.');
                    });  
                    
              })
              .catch((error) => {
                setIsRestoringRecatch(false);
                alert('Could not restore recatch entry. Please try again.');
              });
        }
            
    };
    
    const [editModal, setEditModal] = useState(false);
    function toggleEditModal(catchId, catchIndex) {
        
        if(editModal) {
            setUpdateCatchId('');
            setUpdateCatchIndex(null);
        }
        else {
            getUpdateTag(catchId, catchIndex);
            setUpdateCatchId(catchId);
            setUpdateCatchIndex(catchIndex);
        }
        
        setEditModal(!editModal);
    }
    
    const submitUpdateForm = async (event) => {
    
        setIsUploading(true);
        event.preventDefault();
        
        setIsUpdating(true);
        
        if(updatedImage) {
            updateImage(event);
        }
        else {
            updateCatch(event);
        }
    
    }
    
    const updateImage = async (event) => {
        
        event.preventDefault();
        
        const catchTimestamp = event.target.catch_datetime.value.replace(/[^0-9-]/g, '-');
        const catchUserId = allCatchesLedger[updateCatchIndex].user.id;
        const catchTagNumber = event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, '');
            
      const imageFileName = '/catch-images/' + catchTagNumber + '-' + catchUserId + '-' + catchTimestamp;
      
      const storageRef = ref(storage, imageFileName);
    
      const uploadTask = uploadBytesResumable(storageRef, updatedImage);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("upload is" + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload paused");
              break;
            case "running":
              console.log("Upload running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
          setIsUpdating(false);
            alert('Could not update catch image. Please try again.');
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
            updateCatch(event, downloadedURL);
          });
        }
      );
    };
    
    const updateCatch = async (event, downloadedURL) => {
        
        const speciesId = event.target.fish_species.value;
        const speciesIndex = event.target.fish_species.selectedIndex - 1;
        const catchTimestampFirebase = firebase.firestore.Timestamp.fromDate(new Date(event.target.catch_datetime.value));
        
      //Create object of program data
        const catchData = {
          updated_at: serverTimestamp(),
        };
                
        if(event.target.catch_name.value != allCatchesLedger[updateCatchIndex].name) {
            catchData['name'] = event.target.catch_name.value.replace(/[^0-9a-zA-Z'-,.?& ]/g, '');
        }  
        if(catchTimestampFirebase != allCatchesLedger[updateCatchIndex].catch_date) {
            catchData['catch_date'] = catchTimestampFirebase;
        }  
        if(event.target.location_string.value != allCatchesLedger[updateCatchIndex].location_string) {
            catchData['location_string'] = event.target.location_string.value.replace(/[^0-9a-zA-Z'-,.()/;:"&+_ ]/g, '');
        }             
        if((event.target.catch_latitude.value != allCatchesLedger[updateCatchIndex].location.latitude) || (event.target.catch_longitude.value != allCatchesLedger[updateCatchIndex].location.longitude)) {
            catchData['location'] = new firebase.firestore.GeoPoint(parseFloat(event.target.catch_latitude.value.replace(/[^0-9.-]/g, '')), parseFloat(event.target.catch_longitude.value.replace(/[^0-9.-]/g, '')))
        }
        if(event.target.location_area.value != allCatchesLedger[updateCatchIndex].location_area) {
            catchData['location_area'] = event.target.location_area.value.replace(/[^0-9a-zA-Z'-,.()/;:"&+_ ]/g, '');
        }
        if(event.target.location_city.value != allCatchesLedger[updateCatchIndex].location_city) {
            catchData['location_city'] = event.target.location_city.value.replace(/[^0-9a-zA-Z'-,.()/;:"&+_ ]/g, '');
        } 
        if(event.target.state_string.value != allCatchesLedger[updateCatchIndex].location_state) {
            catchData['location_state'] = event.target.state_string.value.replace(/[^a-zA-Z ]/g, '');
        }
        if(event.target.state_code.value != allCatchesLedger[updateCatchIndex].location_state_code) {
            catchData['location_state_code'] = event.target.state_code.value.replace(/[^a-zA-Z]/g, '');
        }
        if(event.target.catch_country.value != allCatchesLedger[updateCatchIndex].location_country) {
            catchData['location_country'] = event.target.catch_country.value.replace(/[^a-zA-Z-,&+.' ]/g, '');
        }
                
        if((allCatchesLedger[updateCatchIndex].tag_program) && (event.target.tag_program.value != allCatchesLedger[updateCatchIndex].tag_program.id)) {
            catchData['tag_program'] = firebase.firestore().collection('programs').doc(event.target.tag_program.value);
        }
        else if(!allCatchesLedger[updateCatchIndex].tag_program.id) {
            catchData['tag_program'] = firebase.firestore().collection('programs').doc(event.target.tag_program.value);
        }   
        
        if(event.target.tag_number.value != allCatchesLedger[updateCatchIndex].tag_number) {
            catchData['tag_number'] = event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, '');
        }
        if(event.target.fish_species.value != allCatchesLedger[updateCatchIndex].fish_species.id) {
            catchData['fish_species'] = firebase.firestore().collection('species').doc(event.target.fish_species.value);
            catchData['species_name'] = allSpecies[speciesIndex].name;
        }

        if((allCatchesLedger[updateCatchIndex].length) && (event.target.fish_length.value != allCatchesLedger[updateCatchIndex].length)) {
            catchData['length'] = event.target.fish_length.value.replace(/[^0-9.,]/g, '');
        }
        else if((!allCatchesLedger[updateCatchIndex].length) || (allCatchesLedger[updateCatchIndex].length == null) || (allCatchesLedger[updateCatchIndex].length == 0) || (allCatchesLedger[updateCatchIndex].length == '')) {
            catchData['length'] = event.target.fish_length.value.replace(/[^0-9.,]/g, '');
        }
        if((allCatchesLedger[updateCatchIndex].weight) && (event.target.fish_weight.value != allCatchesLedger[updateCatchIndex].weight)) {
            catchData['weight'] = event.target.fish_weight.value.replace(/[^0-9.,]/g, '');
        }
        else if((!allCatchesLedger[updateCatchIndex].weight) || (allCatchesLedger[updateCatchIndex].weight == null) || (allCatchesLedger[updateCatchIndex].weight == 0) || (allCatchesLedger[updateCatchIndex].weight == '')) {
            catchData['weight'] = event.target.fish_weight.value.replace(/[^0-9.,]/g, '');
        }
        if((allCatchesLedger[updateCatchIndex].info) && (event.target.catch_info.value != allCatchesLedger[updateCatchIndex].info)) {
            catchData['info'] = event.target.catch_info.value.replace(/[^0-9a-zA-Z!@%&-=+;:'",.? ]/g, '');
        }
        else if((!allCatchesLedger[updateCatchIndex].info) || (allCatchesLedger[updateCatchIndex].info == '') || (allCatchesLedger[updateCatchIndex].info == null)) {
            catchData['info'] = event.target.catch_info.value.replace(/[^0-9a-zA-Z!@%&-=+;:'",.? ]/g, '');
        }
        
        if(event.target.catch_type.value != allCatchesLedger[updateCatchIndex].recatch) {
            catchData['recatch'] = JSON.parse(event.target.catch_type.value);
        }
        if(event.target.catch_units.value != allCatchesLedger[updateCatchIndex].metric_system) {
            catchData['metric_system'] = JSON.parse(event.target.catch_units.value);
        }
        if(downloadedURL) {
            catchData['image_url'] = downloadedURL;
        }
                
        //Create new catch in firestore
        const catchRef = firebase.firestore().collection('catches').doc(allCatchesLedger[updateCatchIndex].id);
        catchRef
            .update(catchData)
            .then((catchResponse) => {
              alert('The catch has been updated successfully!');
              fetchAllCatchesLedger(); 
            })
            .catch((error) => {
              setIsUpdating(false);
              alert('Could not update catch entry. Please try again.');
            });  
    };
    
    const createList = () => {
                   
           const catchListOptions = {
               valueNames: ['image_url', 'name', 'angler', 'location_string', 'tag_number', 'date', 'type'],
               pagination: true,
               indexAsync: true,
               searchColumns: ['name', 'angler', 'location_string', 'tag_number'],
               page: 10
           };    
         
         new List('catch-list', catchListOptions);
    };
    
    const getUpdateTag = async (catchId, catchIndex) => {
        
        const tagId = allCatchesLedger[catchIndex].tag.id;
        
        firebase
        .firestore()
        .collection('tags')
        .doc(tagId)
        .get()
        .then((tagDoc) => {
            const tagData = tagDoc.data();
            setUpdateTagProgramId(tagData.tag_program.id);
        });  
    };
    
    const fetchAllSpecies = async () => {
          
     firebase
     .firestore()
     .collection('species')
     .orderBy('name', 'asc')
     .onSnapshot(
          querySnapshot => {
              const newSpeciesList = []
              querySnapshot.forEach(doc => {
                  const speciesItem = doc.data();
                  speciesItem.id = doc.id;
                  newSpeciesList.push(speciesItem);
              });
              setAllSpecies(newSpeciesList);
          },
          error => {
              console.log(error);
          }
      )
    };
    
    const fetchTagPrograms = async () => {
          
     firebase
     .firestore()
     .collection('programs')
     .orderBy('created_at', 'desc')
     .onSnapshot(
          querySnapshot => {
              const newProgramsList = []
              querySnapshot.forEach(doc => {
                  const programItem = doc.data();
                  programItem.id = doc.id;
                  
                  const createdAt = programItem && programItem.created_at && programItem.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                    programItem.created = createdAt;
                    const updatedAt = programItem && programItem.updated_at && programItem.updated_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                    programItem.updated = updatedAt;
                  
                  
                  newProgramsList.push(programItem);
              });
              setAllTagPrograms(newProgramsList);
          },
          error => {
              console.log(error);
          }
      )
    };
    
    const fetchAllCatchesLedger = async () => {
        
      const programRef = firebase.firestore().collection('programs').doc(programData.id); 
          
     firebase
     .firestore()
     .collection('catches')
     .where('tag_program', '==', programRef)
     .orderBy('created_at', 'desc')
     .onSnapshot(
          querySnapshot => {
              const newCatchLedger = []
              querySnapshot.forEach(doc => {
                  const catchItem = doc.data();
                  const catchId = doc.id;
                  catchItem.id = catchId;
                  catchItem.angler = catchItem.user_name;
                  catchItem.user_link = 'user/details/' + catchItem.user.id; 
                  
                  const catchDate = catchItem && catchItem.catch_date && catchItem.catch_date.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  catchItem.date = catchDate;
                  catchItem.link = 'catch/details/' + catchId;  
                  catchItem.tag_link = 'tag/details/' + catchItem.tag.id; 
                  newCatchLedger.push(catchItem);
              });
              setAllCatchesLedger(newCatchLedger);
              setEditModal(false);
              setIsUploading(false);
              setIsUpdating(false);
              setmodal_list(false);
              setDeleteCatchModal(false);
              setDeleteRecatchModal(false);
              setIsDeletingCatch(false);
              setIsDeletingRecatch(false);
              setFetchedData(true);
              setRestoreCatchModal(false);
              setRestoreRecatchModal(false);
              setIsRestoringCatch(false);
              setIsRestoringRecatch(false);
          },
          error => {
              console.log(error);
          }
      );
      
    };

    useEffect(() => {

         
        if(initialLoad) {
           setInitialLoad(false);
           fetchAllSpecies();
           fetchTagPrograms();
           fetchAllCatchesLedger();
         }
         
         if(fetchedData && allCatchesLedger && (allCatchesLedger.length > 0)) {
             setFetchedData(false);
             createList();
           }  
        
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <CardTitle>Catch Ledger</CardTitle>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">All Catches</h4>
                                </CardHeader>

                                <CardBody>
                                    <div id="catch-list">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                </div>
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="search" className="fuzzy-search form-control" placeholder="Search Catches..." />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                        {!isUpdating && !isDeletingRecatch && !isDeletingCatch &&
                                            <table className="table align-middle table-nowrap" id="catch-table">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>View</th>
                                                        <th data-sort="image_url">Image</th>
                                                        <th className="sort" data-sort="name">Catch Name</th>
                                                        <th className="sort" data-sort="angler">Angler Name</th>
                                                        <th className="sort" data-sort="location_string">Catch Location</th>
                                                        <th className="sort" data-sort="tag_number">Tag Number</th>
                                                        <th className="sort" data-sort="date" data-default-order='desc'>Catch Date</th>
                                                        <th className="sort" data-sort="type">Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                
                                                
                                                    {(allCatchesLedger.length > 0) && allCatchesLedger.map((catchItem, index) => ( 
                                                    <tr key={index}>
                                                        <th scope="row">
                                                            <a className="btn btn-sm btn-success edit-item-btn" href={catchItem.link}><i className="mdi mdi-eye-outline"></i></a>
                                                        </th>
                                                        <td className="image_url">
                                                            {catchItem.image_url &&<img className="avatar-sm rounded" alt={catchItem.name} src={catchItem.image_url} />}
                                                        </td>
                                                        <td className="name">{catchItem.name}</td>
                                                        <td><a href={catchItem.user_link} className="angler fw-medium link-primary">{catchItem.angler}</a></td>
                                                        <td className="location_string">{catchItem.location_string}</td>
                                                        <td><a href={catchItem.tag_link} className="tag_number fw-medium link-primary">#{catchItem.tag_number}</a></td>
                                                        <td className="date">{catchItem.date}</td>
                                                        <td className="type">
                                                            {catchItem && catchItem.recatch &&
                                                                <span className="badge badge-soft-warning text-uppercase">Recatch</span>
                                                            }
                                                            {catchItem && !catchItem.recatch &&
                                                                <span className="badge badge-soft-success text-uppercase">Catch</span>
                                                            }
                                                        </td>
                                                    </tr>
                                                    ))}
                                                    
                                                </tbody>
                                            </table>}
                                            
                                            {allCatchesLedger.length == 0 &&
                                            <div className="noresult">
                                                <div className="text-center">
          
                                                    <h5 className="mt-2">No Catches Found!</h5>
                                                    <p className="text-muted mb-0">We could not find any catches.</p>
                                                </div>
                                            </div>
                                            }
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                {/* <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link> */}
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                {/* <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    

                    
                </Container>
            </div>
            
            {/* Edit Modal */}
            <Modal isOpen={editModal} toggle={() => { toggleEditModal(); }} centered >
                <ModalHeader className="bg-light p-3" id="editModal" toggle={() => { toggleEditModal(); }}> Edit Catch </ModalHeader>
                
                {!isUpdating && allCatchesLedger[updateCatchIndex] &&
                <form className="tablelist-form" onSubmit={submitUpdateForm}>
                    <ModalBody>            
                        <div className="mb-3">
                            <label htmlFor="name-field" className="form-label">Catch Name*</label>
                            <input type="text" name="catch_name" id="name-field" className="form-control" placeholder="Enter Name" required defaultValue={allCatchesLedger[updateCatchIndex].name}/>
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="date-field" className="form-label">Catch Date*</label>
                            <Flatpickr
                                className="form-control"
                                name="catch_datetime"
                                options={{
                                    enableTime: true,
                                    dateFormat: "Y-m-d H:i",
                                    defaultDate: allCatchesLedger[updateCatchIndex].catch_date.toDate()
                                }}
                                placeholder="Select Date & Time"
                            />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="image-field" className="form-label">Image File*</label>
                            <tr>
                            <td><img className="avatar-sm rounded" alt={allCatchesLedger[updateCatchIndex].name} src={allCatchesLedger[updateCatchIndex].image_url}/></td>
                            <td><input type="file" name="image_link" id="image-field" className="form-control" onChange={(e) => { setUpdatedImage(e.target.files[0]) }} style={{marginLeft: 20, width: "100%"}}/></td>
                            </tr>
                        </div>
            
                        <div className="mb-3">
                            <label htmlFor="locationstring-field" className="form-label">Full Catch Location*</label>
                            <input type="text" id="locationstring-field" className="form-control" name="location_string" placeholder="Toms River" required defaultValue={allCatchesLedger[updateCatchIndex].location_string}/>
                        </div>
            
                        <div className="mb-3"><tr>
                            <td>
                            <label htmlFor="latitude-field" className="form-label">Latitude*</label>
                            <input type="number" id="latitude-field" className="form-control" placeholder="Enter Latitude*" name="catch_latitude" step="any" required defaultValue={allCatchesLedger[updateCatchIndex].location.latitude} />
                            </td>
                            <td>
                            <label htmlFor="longitude-field" className="form-label">Longitude*</label>
                            <input type="number" id="longitude-field" className="form-control" placeholder="Enter Longitude*" name="catch_longitude" step="any" required defaultValue={allCatchesLedger[updateCatchIndex].location.longitude} />
                            </td>
                        </tr>
                        </div>
                        
                        <div className="mb-3">
                            <tr>
                                <td>
                                    <label htmlFor="locationarea-field" className="form-label">Catch Location Area*</label>
                                    <input type="text" id="locationarea-field" className="form-control" name="location_area" placeholder="Toms River" required defaultValue={allCatchesLedger[updateCatchIndex].location_area}/>
                                </td>
                                <td>
                                    <label htmlFor="locationcity-field" className="form-label">Catch Location City*</label>
                                    <input type="text" id="locationcity-field" className="form-control" name="location_city" placeholder="Atlantic City" required defaultValue={allCatchesLedger[updateCatchIndex].location_city}/>
                                </td>
                            </tr>
                        </div>
                        
                        <div className="mb-3">
                            <tr>
                                <td>
                                    <label htmlFor="state-field" className="form-label">Catch State*</label>
                                    <input type="text" id="state-field" className="form-control" name="state_string" placeholder="New York" required defaultValue={allCatchesLedger[updateCatchIndex].location_state}/>
                                </td>
                                <td>
                                    <label htmlFor="statecode-field" className="form-label">State Code*</label>
                                    <input type="text" id="statecode-field" className="form-control" name="state_code" placeholder="NY" required maxLength={2} defaultValue={allCatchesLedger[updateCatchIndex].location_state_code}/>
                                </td>
                            </tr>
                        </div>
                    
                        <div className="mb-3">
                            <label htmlFor="country-field" className="form-label">Catch Country*</label>
                            <select className="form-control" data-trigger id="country-field" required name="catch_country" defaultValue={allCatchesLedger[updateCatchIndex].location_country}>
                                <option value="">Select Catch Country</option>
                                <option value="USA">United States</option>
                                <option value="CAN">Canada</option>
                            </select>
                        </div>
                        
                        {updateTagProgramId &&
                        <div className="mb-3">
                            <label htmlFor="program-field" className="form-label">Tagging Program*</label>
                            <select className="form-control" data-trigger name="tag_program" id="program-field" required defaultValue={updateTagProgramId}>
                                <option value="">Select Tag Program*</option>
                                {allTagPrograms.map(({ program_name, id }, index) => <option value={id} >{program_name}</option>)}
                            </select>
                        </div> 
                        }
            
                        <div className="mb-3">
                            <label htmlFor="tag-field" className="form-label">Tag Number*</label>
                            <input type="text" id="tag-field" name="tag_number" className="form-control" placeholder="Enter Tag Number*" maxLength={15} required defaultValue={allCatchesLedger[updateCatchIndex].tag_number} />
                        </div>
            
                        <div className="mb-3">
                            <label htmlFor="species-field" className="form-label">Fish Species*</label>
                            <select className="form-control" data-trigger name="fish_species" id="species-field" required defaultValue={allCatchesLedger[updateCatchIndex].fish_species.id}>
                                <option value="">Select Fish Species*</option>
                                {allSpecies.map(({ name, id }, index) => <option value={id} >{name}</option>)}
                            </select>
                        </div>   
                        
                        <div className="mb-3"><tr>
                            <td>
                            {allCatchesLedger[updateCatchIndex].metric_system && <label htmlFor="length-field" className="form-label">Fish Length (CM)</label>}
                              {!allCatchesLedger[updateCatchIndex].metric_system && <label htmlFor="length-field" className="form-label">Fish Length (IN)</label>}
                            <input type="number" id="length-field" className="form-control" placeholder="Enter Fish Length" defaultValue={allCatchesLedger[updateCatchIndex].length} name="fish_length"/>
                            </td>
                            <td>
                            {allCatchesLedger[updateCatchIndex].metric_system && <label htmlFor="weight-field" className="form-label">Fish Weight (KG)</label>}
                              {!allCatchesLedger[updateCatchIndex].metric_system && <label htmlFor="weight-field" className="form-label">Fish Weight (LB)</label>}
                            <input type="number" id="weight-field" className="form-control" placeholder="Enter Fish Weight" defaultValue={allCatchesLedger[updateCatchIndex].weight} name="fish_weight"/>
                            </td>
                        </tr></div>
                        
                        <div className="mb-3">
                            <label htmlFor="catchinfo-field" className="form-label">Catch Information</label>
                            <input type="text" id="catchinfo-field" className="form-control" placeholder="Catch Information" defaultValue={allCatchesLedger[updateCatchIndex].info} name="catch_info"/>
                        </div>
            
                        <div className="mb-3"><tr>
                            <td>
                            <label htmlFor="status-field" className="form-label">Catch Type*</label>
                            <select className="form-control" data-trigger name="catch_type" id="status-field" required defaultValue={allCatchesLedger[updateCatchIndex].recatch}>
                                <option value="">Select Catch Type*</option>
                                <option value="false">New Catch</option>
                                <option value="true">Re-Catch</option>
                            </select>
                            </td>
                            <td>
                            <label htmlFor="units-field" className="form-label">Unit System*</label>
                            <select className="form-control" data-trigger name="catch_units" id="units-field" required defaultValue={allCatchesLedger[updateCatchIndex].metric_system}>
                                <option value="">Select Units System*</option>
                                <option value="true">Metric System</option>
                                <option value="false">Imperial System</option>
                            </select>
                            </td>
                        </tr>
                        </div>
                        
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => toggleEditModal() }>Close</button>
                            <button type="submit" className="btn btn-success" id="add-btn">Update</button>
                        </div>
                    </ModalFooter>
                </form>
                }
                
                <ModalBody>
                    <ThreeCircles
                      visible={isUpdating}
                      height="100"
                      width="100"
                      color="#4192C3"
                      ariaLabel="three-circles-loading"
                      wrapperStyle={{style:"margin: 0px auto"}}
                      wrapperClass="justify-content-center"
                      />
                </ModalBody>
            </Modal>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Add Catch </ModalHeader>
                <form className="tablelist-form">
                    <ModalBody>
                        <div className="mb-3" id="modal-id" style={{ display: "none" }}>
                            <label htmlFor="id-field" className="form-label">ID</label>
                            <input type="text" id="id-field" className="form-control" placeholder="ID" readOnly />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="name-field" className="form-label">Catch Name*</label>
                            <input type="text" id="name-field" className="form-control" placeholder="Enter Name" required />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="date-field" className="form-label">Catch Date</label>
                            <Flatpickr
                                className="form-control"
                                options={{
                                    enableTime: true,
                                    dateFormat: "d M, Y H:i"
                                }}
                                placeholder="Select Date & Time"
                            />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="latitude-field" className="form-label">Latitude</label>
                            <input type="number" id="latitude-field" className="form-control" placeholder="Enter Latitude*" required />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="longitude-field" className="form-label">Longitude</label>
                            <input type="number" id="longitude-field" className="form-control" placeholder="Enter Longitude*" required />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="tag-field" className="form-label">Tag Number</label>
                            <input type="number" id="tag-field" className="form-control" placeholder="Enter Tag Number*" required />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="species-field" className="form-label">Fish Species</label>
                            <select className="form-control" data-trigger name="species-field" id="species-field" >
                                <option value="">Select Fish Species*</option>
                            </select>
                        </div>   
                        
                        <div className="mb-3">
                            <label htmlFor="length-field" className="form-label">Fish Length (CM)</label>
                            <input type="number" id="length-field" className="form-control" placeholder="Enter Fish Length" required />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="weight-field" className="form-label">Fish Weight (KG)</label>
                            <input type="number" id="weight-field" className="form-control" placeholder="Enter Fish Weight*" required />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="program-field" className="form-label">Tagging Program</label>
                            <select className="form-control" data-trigger name="program-field" id="program-field" >
                                <option value="">Select Tag Program*</option>
                            </select>
                        </div>   

                        <div className="mb-3">
                            <label htmlFor="status-field" className="form-label">Type</label>
                            <select className="form-control" data-trigger name="status-field" id="status-field" >
                                <option value="Active">New Catch</option>
                                <option value="Block">Re-Catch</option>
                            </select>
                        </div>
                        
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
                            <button type="submit" className="btn btn-success disabled" id="add-btn">Add Catch</button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Delete Recatch Modal */}
            <Modal isOpen={deleteRecatchModal} toggle={() => { toggleDeleteRecatchModal(); }} className="modal zoomIn" id="deleteRecatchModal" centered >
            
                {!isDeletingRecatch &&
                <ModalBody>
                    <div className="mt-2 text-center">
                                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                    <h4>Delete Re-Catch Data</h4>
                                    <p className="text-muted mx-4 mb-0">Are you sure you want to delete this re-catch?</p>
                                </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => toggleDeleteRecatchModal()}>Cancel</button>
                        <button type="button" className="btn w-sm btn-danger" id="delete-record" onClick={deleteRecatch}>Delete</button>
                    </div>
                </ModalBody>
                }
                    
                    
                    <ModalBody>
                        <ThreeCircles
                          visible={isDeletingRecatch}
                          height="100"
                          width="100"
                          color="#ff3d60"
                          ariaLabel="three-circles-loading"
                          wrapperStyle={{style:"margin: 0px auto"}}
                          wrapperClass="justify-content-center"
                          />
                    </ModalBody>
            </Modal>
            
            {/* Remove Modal */}
            <Modal isOpen={deleteCatchModal} toggle={() => { toggleDeleteCatchModal(); }} className="modal zoomIn" id="deleteCatchModal" centered >
            
                {!isDeletingCatch &&
                <ModalBody>
                    <div className="mt-2 text-center">
                                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                    <h4>Delete Catch Data</h4><br/>
                                    <h5 className="text-muted mx-4 mb-0">This will delete the catch, connected re-catches and the fish-tag it used!!!</h5><br/>
                                    <p className="text-muted mx-4 mb-0">Are you sure you want to delete this catch?</p>
                                </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => toggleDeleteCatchModal()}>Cancel</button>
                        <button type="button" className="btn w-sm btn-danger" id="delete-record" onClick={deleteCatch}>Delete</button>
                    </div>
                </ModalBody>
                }
                    
                    
                    <ModalBody>
                        <ThreeCircles
                          visible={isDeletingCatch}
                          height="100"
                          width="100"
                          color="#ff3d60"
                          ariaLabel="three-circles-loading"
                          wrapperStyle={{style:"margin: 0px auto"}}
                          wrapperClass="justify-content-center"
                          />
                    </ModalBody>
            </Modal>
            
            {/* Restore Recatch Modal */}
              <Modal isOpen={restoreRecatchModal} toggle={() => { toggleRestoreRecatchModal(); }} className="modal zoomIn" id="restoreRecatchModal" centered >
              
                  {!isRestoringRecatch &&
                  <ModalBody>
                      <div className="mt-2 text-center">
                                  <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                      <h4>Restore Re-Catch Data</h4>
                                      <p className="text-muted mx-4 mb-0">Do you want to restore this re-catch?</p>
                                  </div>
                      </div>
                      <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                          <button type="button" className="btn w-sm btn-light" onClick={() => toggleRestoreRecatchModal()}>Cancel</button>
                          <button type="button" className="btn w-sm btn-success" id="delete-record" onClick={restoreRecatch}>Restore</button>
                      </div>
                  </ModalBody>
                  }
                      
                      
                      <ModalBody>
                          <ThreeCircles
                            visible={isRestoringRecatch}
                            height="100"
                            width="100"
                            color="#4192C3"
                            ariaLabel="three-circles-loading"
                            wrapperStyle={{style:"margin: 0px auto"}}
                            wrapperClass="justify-content-center"
                            />
                      </ModalBody>
              </Modal>
              
              {/* Restore Modal */}
              <Modal isOpen={restoreCatchModal} toggle={() => { toggleRestoreCatchModal(); }} className="modal zoomIn" id="restoreCatchModal" centered >
              
                  {!isRestoringCatch &&
                  <ModalBody>
                      <div className="mt-2 text-center">
                                  <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                      <h4>Restore Catch Data</h4><br/>
                                      <h5 className="text-muted mx-4 mb-0">This will restore the catch, connected re-catches and the fish-tag it used!!!</h5><br/>
                                      <p className="text-muted mx-4 mb-0">Are you sure you want to restore this catch?</p>
                                  </div>
                      </div>
                      <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                          <button type="button" className="btn w-sm btn-light" onClick={() => toggleRestoreCatchModal()}>Cancel</button>
                          <button type="button" className="btn w-sm btn-success" id="delete-record" onClick={restoreCatch}>Restore</button>
                      </div>
                  </ModalBody>
                  }
                      
                      
                      <ModalBody>
                          <ThreeCircles
                            visible={isRestoringCatch}
                            height="100"
                            width="100"
                            color="#4192C3"
                            ariaLabel="three-circles-loading"
                            wrapperStyle={{style:"margin: 0px auto"}}
                            wrapperClass="justify-content-center"
                            />
                      </ModalBody>
              </Modal>
            
        </React.Fragment>
    );
};

export default AllCatches;
