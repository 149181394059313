import React, { useRef, useState, useEffect } from 'react'
import { Button, Card, CardBody, CardTitle, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, ModalHeader } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from 'simplebar-react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import List from 'list.js';
import Flatpickr from "react-flatpickr";
import { ThreeCircles } from 'react-loader-spinner'

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import {serverTimestamp} from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, uploadBytes, getStorage, ref } from "firebase/storage";

const CatchDetails = () => {
  
  document.title = "Catch Details | Fish-Trak - Partner's Dashboard";
  
  //Mounted variables
  const { catchId } = useParams();
  const storage = getStorage();
  const navigate = useNavigate();
  const catchesRef = firebase.firestore().collection('catches');
  
  //Session booleans
  const [initialLoad, setInitialLoad] = useState(true);
  const [fetchingDetails, setFetchingDetails] = useState(true);
  const [fetchingLedger, setFetchingLedger] = useState(true);
  const [fetchingUser, setFetchingUser] = useState(true);
  const [fetchedData, setFetchedData] = useState(false);
  
  //Data variables
  const [catchTagId, setCatchTagId] = useState({});
  const [catchObject, setCatchObject] = useState(null);
  const [userObject, setUserObject] = useState({});
  const [catchLedger, setCatchLedger] = useState(null);
  
  //Fetched data
  const [allSpecies, setAllSpecies] = useState([]);
  const [allTagPrograms, setAllTagPrograms] = useState([]);
  
  //Action buttons
  const [isUploading, setIsUploading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeletingRecatch, setIsDeletingRecatch] = useState(false);
  const [isDeletingCatch, setIsDeletingCatch] = useState(false);
  const [isRestoringRecatch, setIsRestoringRecatch] = useState(false);
  const [isRestoringCatch, setIsRestoringCatch] = useState(false);
  const [updatedImage, setUpdatedImage] = useState(null);
  const [updateTagProgram, setUpdateTagProgram] = useState(null);
  
  //Get user details
  const getCatchDetails = async () => {
    
    setFetchingDetails(true);
    
    catchesRef
    .doc(catchId)
    .get()
    .then((catchDoc) => {
      const catchData = catchDoc.data();  
      catchData.id = catchDoc.id;
      const catchDate = catchData && catchData.catch_date && catchData.catch_date.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
      catchData.date = catchDate;
      const catchTime = catchData && catchData.catch_date && catchData.catch_date.toDate().toLocaleString('en-US', {hour: '2-digit', hour12: false, minute:'2-digit', timeZone: "America/New_York"});
      catchData.time = catchTime;
      
      catchData.tag_link = '../../tag/details/' + catchData.tag.id; 
      catchData.species_link = '../../species/details/' + catchData.fish_species.id; 
          
      setCatchObject(catchData);
      setFetchingDetails(false);
      setCatchTagId(catchData.tag.id);
      getCatchLedger(catchData.tag.id);
      getUserDetails(catchData.user.id);
      getUpdateTag(catchData.tag.id);
      
      setEditModal(false);
      setIsUploading(false);
      setIsUpdating(false);
      setDeleteCatchModal(false);
      setDeleteRecatchModal(false);
      setIsDeletingCatch(false);
      setIsDeletingRecatch(false);
      
      setRestoreCatchModal(false);
      setRestoreRecatchModal(false);
      setIsRestoringCatch(false);
      setIsRestoringRecatch(false);
      
    })
    .catch((error) => {
      setFetchingDetails(false);
      alert('Could not get catch details. Please try again.');
    });  
  };
  
  //Get user details
  const getUserDetails = async (userId) => {
    
    setFetchingUser(true);
    firebase
    .firestore()
    .collection('users')
    .doc(userId)
    .get()
    .then((userDoc) => {
      const userData = userDoc.data();
      userData.id = userDoc.id;
      const userLastName = !!userData.last_name ? userData.last_name : '';
      userData.full_name = userData.first_name + ' ' + userLastName;
      userData.link = '../../user/details/' + userData.id; 
      setUserObject(userData);
      setFetchingUser(false);
    })
    .catch((error) => {
      setFetchingUser(false);
      alert('Could not get user details. Please try again.');
    });  
  };
  
  //Get user catches
  const getCatchLedger = async (tagId) => {
    setFetchingLedger(true);
    const tagRef = firebase.firestore().collection('tags').doc(tagId);
    
    catchesRef
     .where('tag', '==', tagRef)
     .orderBy('catch_date', 'desc')
     .onSnapshot(
          querySnapshot => {
              const catchLedgerArray = []
              querySnapshot.forEach(doc => {
                
                if(doc.id != catchId) {
                  const catchItem = doc.data();                  
                    catchItem.id = doc.id;
                    
                    catchItem.angler = catchItem.user_name;
                    catchItem.user_link = '../../user/details/' + catchItem.user.id; 
                    
                    const catchDate = catchItem && catchItem.catch_date && catchItem.catch_date.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                    catchItem.date = catchDate;
                    catchItem.link = '../../catch/details/' + catchItem.id;  
                    catchItem.tag_link = '../../tag/details/' + catchItem.tag.id; 
                    
                    catchLedgerArray.push(catchItem);
                  }
                  
              });
              setCatchLedger(catchLedgerArray);
              setFetchingLedger(false);
              setFetchedData(true);
          },
          error => {
              console.log(error);
              setFetchingLedger(false);
          }
      );
  }
  
  const [deleteRecatchModal, setDeleteRecatchModal] = useState(false);
  function toggleDeleteRecatchModal() {
      
      setDeleteRecatchModal(!deleteRecatchModal);
      
  }
  
  const [deleteCatchModal, setDeleteCatchModal] = useState(false);
  function toggleDeleteCatchModal() {
      
      setDeleteCatchModal(!deleteCatchModal);
      
  }
  
  const [restoreRecatchModal, setRestoreRecatchModal] = useState(false);
  function toggleRestoreRecatchModal() {
      
      setRestoreRecatchModal(!restoreRecatchModal);
      
  }
  
  const [restoreCatchModal, setRestoreCatchModal] = useState(false);
  function toggleRestoreCatchModal() {
      
      setRestoreCatchModal(!restoreCatchModal);
      
  }
  
  const deleteCatch = () => {
      
      setIsDeletingCatch(true);
      
      const usersRef = firebase.firestore().collection('users');
      const tagRef = firebase.firestore().collection('tags').doc(catchObject.tag.id);
      const catchesRef = firebase.firestore().collection('catches');
      
      //Get all catches using the same tag
      catchesRef
       .where('tag', '==', tagRef)
       .onSnapshot(
            querySnapshot => {
                querySnapshot.forEach(doc => {
                    const catchItem = doc.data();
                    
                    //For each catch decrement user catch/recatch count
                    const userData = {
                      updated_at: serverTimestamp(),
                    };
                    if(catchItem.recatch) {                        
                        if(!catchItem.recatch_count || catchItem.recatch_count == 0 || catchItem.recatch_count == 1 || catchItem.recatch_count == '' || catchItem.recatch_count == null) {
                            userData['recatch_count'] = 0; 
                          }
                          else {
                              userData['recatch_count'] = firebase.firestore.FieldValue.increment(-1);
                          }
                      }  
                      else {
                          if(!catchItem.catch_count || catchItem.catch_count == 0 || catchItem.catch_count == 1 || catchItem.catch_count == '' || catchItem.catch_count == null) {
                            userData['catch_count'] = 0; 
                          }
                          else {
                              userData['catch_count'] = firebase.firestore.FieldValue.increment(-1);
                          }
                      }
                    
                    usersRef
                    .doc(catchItem.user.id)
                    .update(userData)
                    .then((userResponse) => {
                        
                        //Delete the catch or recatch
                        catchesRef
                          .doc(doc.id)
                          .update({
                            updated_at: serverTimestamp(),
                            deleted: true
                          })
                          .then(() => {
                              
                              console.log('Deleted catch');
                             
                          })
                          .catch((error) => {
                            setIsDeletingCatch(false);
                            alert('Could not delete catch entry. Please try again.');
                          });
                        
                      
                    })
                    .catch((error) => {
                      setIsDeletingCatch(false);
                      alert('Could not update user status. Please try again.');
                    }); 
                    
                });
                
                tagRef
                  .update({
                    updated_at: serverTimestamp(),
                    deleted: true
                  })
                  .then(() => {
                      getCatchDetails();                     
                  })
                  .catch((error) => {
                    setIsDeletingCatch(false);
                    alert('Could not delete fish tag entry. Please try again.');
                  });
                
            },
            error => {
                console.log(error);
            }
        );   
        
  }
  
  const restoreCatch = () => {
      
      setIsRestoringCatch(true);
      
      const usersRef = firebase.firestore().collection('users');
      const tagRef = firebase.firestore().collection('tags').doc(catchObject.tag.id);
      const catchesRef = firebase.firestore().collection('catches');
      
      //Get all catches using the same tag
      catchesRef
       .where('tag', '==', tagRef)
       .onSnapshot(
            querySnapshot => {
                querySnapshot.forEach(doc => {
                    const catchItem = doc.data();
                    
                    //For each catch decrement user catch/recatch count
                    const userData = {
                      updated_at: serverTimestamp(),
                    };
                    if(catchItem.recatch) {                        
                        userData['recatch_count'] = firebase.firestore.FieldValue.increment(1);
                      }  
                      else {
                        userData['catch_count'] = firebase.firestore.FieldValue.increment(1);
                      }
                    
                    usersRef
                    .doc(catchItem.user.id)
                    .update(userData)
                    .then((userResponse) => {
                        
                        //Delete the catch or recatch
                        catchesRef
                          .doc(doc.id)
                          .update({
                            deleted: false,
                            updated_at: serverTimestamp()
                          })
                          .then(() => {
                              
                              console.log('Restored catch');
                             
                          })
                          .catch((error) => {
                            setIsRestoringCatch(false);
                            alert('Could not restore catch entry. Please try again.');
                          });
                        
                      
                    })
                    .catch((error) => {
                      setIsRestoringCatch(false);
                      alert('Could not update user status. Please try again.');
                    }); 
                    
                });
                
                tagRef
                  .update({
                    deleted: false,
                    updated_at: serverTimestamp()
                  })
                  .then(() => {
                      getCatchDetails();
                  })
                  .catch((error) => {
                    setIsRestoringCatch(false);
                    alert('Could not restore fish tag entry. Please try again.');
                  });
                
            },
            error => {
                console.log(error);
            }
        );   
        
  }
      
  
  const deleteRecatch = async () => {
      
      setIsDeletingRecatch(true);
              
      const userRef = firebase.firestore().collection('users').doc(catchObject.user.id);
      const tagRef = firebase.firestore().collection('tags').doc(catchObject.tag.id);
      const catchRef = firebase.firestore().collection('catches').doc(catchId);  
      
      if(catchObject.recatch) {
          catchRef
            .update({
              updated_at: serverTimestamp(),
              deleted: true
            })
            .then(() => {
              tagRef
                  .update({
                      updated_at: serverTimestamp(),
                      catch_count: firebase.firestore.FieldValue.increment(-1),
                      latest_anglers: firebase.firestore.FieldValue.arrayRemove(userRef)
                    })
                  .then((tagResponse) => {
                      
                      userRef
                      .update({
                          updated_at: serverTimestamp(),
                          recatch_count: firebase.firestore.FieldValue.increment(-1)
                        })
                      .then((userResponse) => {
                        
                        getCatchDetails();
                        alert('The recatch has been deleted successfully!');
                        
                      })
                      .catch((error) => {
                        setIsDeletingRecatch(false);
                        alert('Could not update user status. Please try again.');
                      }); 
  
                    
                  })
                  .catch((error) => {
                    setIsDeletingRecatch(false);
                    alert('Could not update tag status. Please try again.');
                  });  
                  
            })
            .catch((error) => {
              setIsDeletingRecatch(false);
              alert('Could not delete recatch entry. Please try again.');
            });
      }
          
  };
  
  const restoreRecatch = async () => {
      
      setIsRestoringRecatch(true);
              
      const userRef = firebase.firestore().collection('users').doc(catchObject.user.id);
      const tagRef = firebase.firestore().collection('tags').doc(catchObject.tag.id);
      const catchRef = firebase.firestore().collection('catches').doc(catchId);  
      
      tagRef
      .get()
      .then((tagDoc) => {
        
        const tagData = tagDoc.data();
        tagData.id = tagDoc.id;
        
        if(tagData.deleted) {
          setRestoreRecatchModal(false);
          setIsRestoringRecatch(false);
          alert('The main catch and tag must be restored first!');
        }
        else {
          if(catchObject.recatch) {
              catchRef
                .update({
                  updated_at: serverTimestamp(),
                  deleted: false
                })
                .then(() => {
                  tagRef
                      .update({
                          updated_at: serverTimestamp(),
                          catch_count: firebase.firestore.FieldValue.increment(1),
                          latest_anglers: firebase.firestore.FieldValue.arrayUnion(userRef)
                        })
                      .then((tagResponse) => {
                          
                          userRef
                          .update({
                              updated_at: serverTimestamp(),
                              recatch_count: firebase.firestore.FieldValue.increment(1)
                            })
                          .then((userResponse) => {
                            
                            getCatchDetails();
                            alert('The recatch has been restored successfully!');
                            
                          })
                          .catch((error) => {
                            setIsRestoringRecatch(false);
                            alert('Could not update user status. Please try again.');
                          }); 
          
                        
                      })
                      .catch((error) => {
                        setIsRestoringRecatch(false);
                        alert('Could not update tag status. Please try again.');
                      });  
                      
                })
                .catch((error) => {
                  setIsRestoringRecatch(false);
                  alert('Could not restore recatch entry. Please try again.');
                });
          }
        }
        
      });
      
      
          
  };
  
  const [editModal, setEditModal] = useState(false);
  function toggleEditModal() {
      
      setEditModal(!editModal);
  }
  
  const submitUpdateForm = async (event) => {
  
      setIsUploading(true);
      event.preventDefault();
      
      setIsUpdating(true);
      
      if(updatedImage) {
          updateImage(event);
      }
      else {
          updateCatch(event);
      }
  
  }
  
  const updateImage = async (event) => {
      
      event.preventDefault();
      
      const catchTimestamp = event.target.catch_datetime.value.replace(/[^0-9-]/g, '-');
      const catchUserId = catchObject.user.id;
      const catchTagNumber = event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, '');
          
    const imageFileName = '/catch-images/' + catchTagNumber + '-' + catchUserId + '-' + catchTimestamp;
    
    const storageRef = ref(storage, imageFileName);
  
    const uploadTask = uploadBytesResumable(storageRef, updatedImage);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("upload is" + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload paused");
            break;
          case "running":
            console.log("Upload running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
        setIsUpdating(false);
          alert('Could not update catch image. Please try again.');
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
          updateCatch(event, downloadedURL);
        });
      }
    );
  };
  
  const updateCatch = async (event, downloadedURL) => {
      
      const speciesId = event.target.fish_species.value;
      const speciesIndex = event.target.fish_species.selectedIndex - 1;
      const catchTimestampFirebase = firebase.firestore.Timestamp.fromDate(new Date(event.target.catch_datetime.value));
      
    //Create object of program data
      const catchData = {
        updated_at: serverTimestamp(),
      };
              
      if(event.target.catch_name.value != catchObject.name) {
          catchData['name'] = event.target.catch_name.value.replace(/[^0-9a-zA-Z'-,.?& ]/g, '');
      }  
      if(catchTimestampFirebase != catchObject.catch_date) {
          catchData['catch_date'] = catchTimestampFirebase;
      }  
      if(event.target.location_string.value != catchObject.location_string) {
          catchData['location_string'] = event.target.location_string.value.replace(/[^0-9a-zA-Z'-,.()/;:"&+_ ]/g, '');
      }             
      if((event.target.catch_latitude.value != catchObject.location.latitude) || (event.target.catch_longitude.value != catchObject.location.longitude)) {
          catchData['location'] = new firebase.firestore.GeoPoint(parseFloat(event.target.catch_latitude.value.replace(/[^0-9.-]/g, '')), parseFloat(event.target.catch_longitude.value.replace(/[^0-9.-]/g, '')))
      }
      if(event.target.location_area.value != catchObject.location_area) {
          catchData['location_area'] = event.target.location_area.value.replace(/[^0-9a-zA-Z'-,.()/;:"&+_ ]/g, '');
      }
      if(event.target.location_city.value != catchObject.location_city) {
          catchData['location_city'] = event.target.location_city.value.replace(/[^0-9a-zA-Z'-,.()/;:"&+_ ]/g, '');
      } 
      if(event.target.state_string.value != catchObject.location_state) {
          catchData['location_state'] = event.target.state_string.value.replace(/[^a-zA-Z ]/g, '');
      }
      if(event.target.state_code.value != catchObject.location_state_code) {
          catchData['location_state_code'] = event.target.state_code.value.replace(/[^a-zA-Z]/g, '');
      }
      if(event.target.catch_country.value != catchObject.location_country) {
          catchData['location_country'] = event.target.catch_country.value.replace(/[^a-zA-Z-,&+.' ]/g, '');
      }
              
      if((updateTagProgram.tag_program) && (event.target.tag_program.value != updateTagProgram.program_id)) {
          catchData['tag_program'] = firebase.firestore().collection('programs').doc(event.target.tag_program.value);
      }
      else if(!catchObject.tag_program) {
          catchData['tag_program'] = firebase.firestore().collection('programs').doc(event.target.tag_program.value);
      }   
      
      if(event.target.tag_number.value != catchObject.tag_number) {
          catchData['tag_number'] = event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, '');
      }
      if(event.target.fish_species.value != catchObject.fish_species.id) {
          catchData['fish_species'] = firebase.firestore().collection('species').doc(event.target.fish_species.value);
          catchData['species_name'] = allSpecies[speciesIndex].name;
      }
  
      if((catchObject.length) && (event.target.fish_length.value != catchObject.length)) {
          catchData['length'] = event.target.fish_length.value.replace(/[^0-9.,]/g, '');
      }
      else if((!catchObject.length) || (catchObject.length == null) || (catchObject.length == 0) || (catchObject.length == '')) {
          catchData['length'] = event.target.fish_length.value.replace(/[^0-9.,]/g, '');
      }
      if((catchObject.weight) && (event.target.fish_weight.value != catchObject.weight)) {
          catchData['weight'] = event.target.fish_weight.value.replace(/[^0-9.,]/g, '');
      }
      else if((!catchObject.weight) || (catchObject.weight == null) || (catchObject.weight == 0) || (catchObject.weight == '')) {
          catchData['weight'] = event.target.fish_weight.value.replace(/[^0-9.,]/g, '');
      }
      if((catchObject.info) && (event.target.catch_info.value != catchObject.info)) {
          catchData['info'] = event.target.catch_info.value.replace(/[^0-9a-zA-Z!@%&-=+;:'",.? ]/g, '');
      }
      else if((!catchObject.info) || (catchObject.info == '') || (catchObject.info == null)) {
          catchData['info'] = event.target.catch_info.value.replace(/[^0-9a-zA-Z!@%&-=+;:'",.? ]/g, '');
      }
      
      if(event.target.catch_type.value != catchObject.recatch) {
          catchData['recatch'] = JSON.parse(event.target.catch_type.value);
      }
      if(event.target.catch_units.value != catchObject.metric_system) {
          catchData['metric_system'] = JSON.parse(event.target.catch_units.value);
      }
      if(downloadedURL) {
          catchData['image_url'] = downloadedURL;
      }
              
      //Create new catch in firestore
      const catchRef = firebase.firestore().collection('catches').doc(catchObject.id);
      catchRef
          .update(catchData)
          .then((catchResponse) => {
            alert('The catch has been updated successfully!');
            getCatchDetails();
          })
          .catch((error) => {
            setIsUpdating(false);
            alert('Could not update catch entry. Please try again.');
          });  
  };
  
  const createList = () => {
                 
         const catchListOptions = {
             valueNames: ['image_url', 'name', 'angler', 'location_string', 'tag_number', 'date', 'type'],
             pagination: true,
             indexAsync: true,
             searchColumns: ['name', 'angler', 'location_string', 'tag_number'],
             page: 10
         };    
       
       new List('connected-catch-list', catchListOptions);
  };
  
  const getUpdateTag = async (tagId) => {
      
      firebase
      .firestore()
      .collection('tags')
      .doc(tagId)
      .get()
      .then((tagDoc) => {
          const tagData = tagDoc.data();
          tagData.id = tagDoc.id;
          tagData.program_id = tagData.tag_program.id;
          tagData.program_link = '../../program/details/' + tagData.tag_program.id; 
          setUpdateTagProgram(tagData);
      });  
  };
  
  const fetchAllSpecies = async () => {
        
   firebase
   .firestore()
   .collection('species')
   .orderBy('name', 'asc')
   .onSnapshot(
        querySnapshot => {
            const newSpeciesList = []
            querySnapshot.forEach(doc => {
                const speciesItem = doc.data();
                speciesItem.id = doc.id;
                newSpeciesList.push(speciesItem);
            });
            setAllSpecies(newSpeciesList);
        },
        error => {
            console.log(error);
        }
    )
  };
  
  const fetchTagPrograms = async () => {
        
   firebase
   .firestore()
   .collection('programs')
   .orderBy('created_at', 'desc')
   .onSnapshot(
        querySnapshot => {
            const newProgramsList = []
            querySnapshot.forEach(doc => {
                const programItem = doc.data();
                programItem.id = doc.id;
                
                const createdAt = programItem && programItem.created_at && programItem.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  programItem.created = createdAt;
                  const updatedAt = programItem && programItem.updated_at && programItem.updated_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  programItem.updated = updatedAt;
                
                
                newProgramsList.push(programItem);
            });
            setAllTagPrograms(newProgramsList);
        },
        error => {
            console.log(error);
        }
    )
  };
  
  useEffect(() => {
    if(initialLoad) {
       setInitialLoad(false);
       if(catchId) { 
          getCatchDetails();
        }
        fetchAllSpecies();
        fetchTagPrograms();
     }
     
     if(fetchedData && !fetchingUser && !fetchingDetails && catchObject && catchLedger && (catchLedger.length > 0)) {
        setFetchedData(false);
        createList();
      }  
  });
  
  return (
    <React.Fragment>
        
      <div className="page-content">

        <Container fluid>          
          <Row>
            <Col lg="7">
              <Card>
                <CardHeader>
                    <h4 className="card-title mb-0">Catch Details</h4>
                </CardHeader>
              
                <CardBody>
                
                <ThreeCircles
                visible={fetchingDetails || !updateTagProgram}
                height="100"
                width="100"
                color="#4192C3"
                ariaLabel="three-circles-loading"
                wrapperClass="justify-content-center"
                />
                
                {!fetchingDetails && updateTagProgram &&
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={catchObject.image_url}
                        alt={catchObject.name}
                        className="avatar-xl rounded img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>
                          <span className="me-3">{catchObject.name} </span>
                          {catchObject && catchObject.recatch &&
                            <span className="badge badge-soft-success text-uppercase">Recatch</span>
                          }
                          {catchObject && !catchObject.recatch &&
                            <span className="badge badge-soft-danger text-uppercase">Catch</span>
                          }
                        </h5>
                        <p className="mb-1">Catch Date: {catchObject.date}</p>
                        <p className="mb-0">Catch Time: {catchObject.time}</p>
                        <p className="mb-0">Fish Tag: <a href={catchObject.tag_link} className="tag_number fw-medium link-primary">#{catchObject.tag_number}</a></p>
                        <p className="mb-0">Fish Species: <a href={catchObject.species_link} className="species fw-medium link-primary">{catchObject.species_name}</a></p>
                        {/* <p className="mb-0">Tagging Program: <a href={updateTagProgram.program_link} className="tag_number fw-medium link-primary">{updateTagProgram.program_name}</a></p> */}
                      </div>
                    </div>
                    {/* <div className="d-flex gap-2">
                        <div className="edit">
                            <button className="btn btn-md btn-warning edit-item-btn" data-bs-toggle="modal" onClick={() => toggleEditModal()} data-bs-target="#updateModal"><i className="mdi mdi-square-edit-outline"></i></button>
                        </div>
                        <div className="remove">
                          {catchObject && catchObject.recatch && !catchObject.deleted &&
                            <button className="btn btn-md btn-danger remove-item-btn" data-bs-toggle="modal" onClick={() => toggleDeleteRecatchModal()} data-bs-target="#deleteModal"><i className="mdi mdi-delete-outline"></i></button>
                          }
                          {catchObject && !catchObject.recatch && !catchObject.deleted &&
                            <button className="btn btn-md btn-danger remove-item-btn" data-bs-toggle="modal" onClick={() => toggleDeleteCatchModal()} data-bs-target="#deleteModal"><i className="mdi mdi-delete-outline"></i></button>
                          }
                          
                          {catchObject && catchObject.recatch && catchObject.deleted &&
                            <button className="btn btn-md btn-success remove-item-btn" data-bs-toggle="modal" onClick={() => toggleRestoreRecatchModal()} data-bs-target="#restoreRecatchModal"><i className="mdi mdi-history"></i></button>
                          }
                          {catchObject && !catchObject.recatch && catchObject.deleted &&
                            <button className="btn btn-md btn-success remove-item-btn" data-bs-toggle="modal" onClick={() => toggleRestoreCatchModal()} data-bs-target="#restoreCatchModal"><i className="mdi mdi-history"></i></button>
                          }
                        </div>
                    </div> */}
                    
                  </div>
                }
                </CardBody>
              </Card>
            </Col>
            
            <Col lg="5">
              <Card>
                <CardHeader>
                    <h4 className="card-title mb-0">Angler Details</h4>
                </CardHeader>
                <CardBody>
                
                <ThreeCircles
                visible={fetchingUser}
                height="100"
                width="100"
                color="#4192C3"
                ariaLabel="three-circles-loading"
                wrapperClass="justify-content-center"
                />
                
                {!fetchingUser &&
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={userObject.image_url}
                        alt={userObject.full_name}
                        className="avatar-xl rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{userObject.full_name}</h5>
                        <p className="mb-1">{userObject.email_address}</p>
                        <p className="mb-0">{userObject.mobile_number}</p>
                        <p className="mb-0">{userObject.home_port}</p>
                      </div>
                    </div>
                    <div className="d-flex gap-2">
                        <div className="edit">
                            <a className="btn btn-md btn-success edit-item-btn" href={userObject.link}><i className="mdi mdi-eye-outline"></i></a>
                        </div>
                    </div>
                  </div>
                }
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          <Row>
              <Col lg={12}>
                  <Card>
                      <CardHeader>
                          <h4 className="card-title mb-0">Connected Catches</h4>
                      </CardHeader>
          
                      <CardBody>
                          <div id="connected-catch-list">
                              <Row className="g-4 mb-3">
                                  <Col className="col-sm">
                                          <div className="search-box ms-2">
                                              <input type="search" className="fuzzy-search form-control" placeholder="Search Catches..." />
                                          </div>
                                  </Col>
                              </Row>
          
                              <div className="table-responsive table-card mt-3 mb-1">
                                  <table className="table align-middle table-nowrap" id="connected-catch-table">
                                      <thead className="table-light">
                                          <tr>
                                              <th scope="col" style={{ width: "50px" }}>View</th>
                                              <th data-sort="image_url">Image</th>
                                              <th className="sort" data-sort="name">Catch Name</th>
                                              <th className="sort" data-sort="angler">Angler Name</th>
                                              <th className="sort" data-sort="location_string">Catch Location</th>
                                              <th className="sort" data-sort="tag_number">Tag Number</th>
                                              <th className="sort" data-sort="date" data-default-order='desc'>Catch Date</th>
                                              <th className="sort" data-sort="type">Type</th>
                                          </tr>
                                      </thead>
                                      <tbody className="list form-check-all">
                                      
                                      
                                          {catchLedger && (catchLedger.length > 0) && catchLedger.map((catchItem, index) => ( 
                                          <tr key={index}>
                                              <th scope="row">
                                                  <a className="btn btn-sm btn-success edit-item-btn" href={catchItem.link}><i className="mdi mdi-eye-outline"></i></a>
                                              </th>
                                              <td className="image_url">
                                                  {catchItem.image_url &&<img className="avatar-sm rounded" alt={catchItem.name} src={catchItem.image_url} />}
                                              </td>
                                              <td className="name">{catchItem.name}</td>
                                              <td><a href={catchItem.user_link} className="angler fw-medium link-primary">{catchItem.angler}</a></td>
                                              <td className="location_string">{catchItem.location_string}</td>
                                              <td><a href={catchItem.tag_link} className="tag_number fw-medium link-primary">#{catchItem.tag_number}</a></td>
                                              <td className="date">{catchItem.date}</td>
                                              <td className="type">
                                                  {catchItem && catchItem.recatch &&
                                                      <span className="badge badge-soft-warning text-uppercase">Recatch</span>
                                                  }
                                                  {catchItem && !catchItem.recatch &&
                                                      <span className="badge badge-soft-success text-uppercase">Catch</span>
                                                  }
                                              </td>
                                          </tr>
                                          ))}
                                          
                                      </tbody>
                                  </table>
                                  
                                  <ThreeCircles
                                  visible={fetchingLedger}
                                  height="100"
                                  width="100"
                                  color="#4192C3"
                                  ariaLabel="three-circles-loading"
                                  wrapperClass="justify-content-center"
                                  />
                                  
                                  {(!fetchingLedger) && fetchedData && !fetchingDetails && !fetchingUser && (!catchLedger || catchLedger.length == 0) &&
                                  <div className="noresult">
                                      <div className="text-center">
          
                                          <h5 className="mt-2">No Catches Found!</h5>
                                          <p className="text-muted mb-0">We could not find any catches.</p>
                                      </div>
                                  </div>
                                  }
                              </div>
          
                              <div className="d-flex justify-content-end">
                                  <div className="pagination-wrap hstack gap-2">
                                      <ul className="pagination listjs-pagination mb-0"></ul>
                                  </div>
                              </div>
                          </div>
                      </CardBody>
                  </Card>
              </Col>
          </Row>
          
          
        </Container>
      </div>
      
      {/* Edit Modal */}
      <Modal isOpen={editModal} toggle={() => { toggleEditModal(); }} centered >
          <ModalHeader className="bg-light p-3" id="editModal" toggle={() => { toggleEditModal(); }}> Edit Catch </ModalHeader>
          
          {!isUpdating && catchObject &&
          <form className="tablelist-form" onSubmit={submitUpdateForm}>
              <ModalBody>            
                  <div className="mb-3">
                      <label htmlFor="name-field" className="form-label">Catch Name*</label>
                      <input type="text" name="catch_name" id="name-field" className="form-control" placeholder="Enter Name" required defaultValue={catchObject.name}/>
                  </div>
                  
                  <div className="mb-3">
                      <label htmlFor="date-field" className="form-label">Catch Date*</label>
                      <Flatpickr
                          className="form-control"
                          name="catch_datetime"
                          options={{
                              enableTime: true,
                              dateFormat: "Y-m-d H:i",
                              defaultDate: catchObject.catch_date.toDate()
                          }}
                          placeholder="Select Date & Time"
                      />
                  </div>
                  
                  <div className="mb-3">
                      <label htmlFor="image-field" className="form-label">Image File*</label>
                      <tr>
                      <td><img className="avatar-sm rounded" alt={catchObject.name} src={catchObject.image_url}/></td>
                      <td><input type="file" name="image_link" id="image-field" className="form-control" onChange={(e) => { setUpdatedImage(e.target.files[0]) }} style={{marginLeft: 20, width: "100%"}}/></td>
                      </tr>
                  </div>
      
                  <div className="mb-3">
                      <label htmlFor="locationstring-field" className="form-label">Full Catch Location*</label>
                      <input type="text" id="locationstring-field" className="form-control" name="location_string" placeholder="Toms River" required defaultValue={catchObject.location_string}/>
                  </div>
      
                  <div className="mb-3"><tr>
                      <td>
                      <label htmlFor="latitude-field" className="form-label">Latitude*</label>
                      <input type="number" id="latitude-field" className="form-control" placeholder="Enter Latitude*" name="catch_latitude" step="any" required defaultValue={catchObject.location.latitude} />
                      </td>
                      <td>
                      <label htmlFor="longitude-field" className="form-label">Longitude*</label>
                      <input type="number" id="longitude-field" className="form-control" placeholder="Enter Longitude*" name="catch_longitude" step="any" required defaultValue={catchObject.location.longitude} />
                      </td>
                  </tr>
                  </div>
                  
                  <div className="mb-3">
                      <tr>
                          <td>
                              <label htmlFor="locationarea-field" className="form-label">Catch Location Area*</label>
                              <input type="text" id="locationarea-field" className="form-control" name="location_area" placeholder="Toms River" required defaultValue={catchObject.location_area}/>
                          </td>
                          <td>
                              <label htmlFor="locationcity-field" className="form-label">Catch Location City*</label>
                              <input type="text" id="locationcity-field" className="form-control" name="location_city" placeholder="Atlantic City" required defaultValue={catchObject.location_city}/>
                          </td>
                      </tr>
                  </div>
                  
                  <div className="mb-3">
                      <tr>
                          <td>
                              <label htmlFor="state-field" className="form-label">Catch State*</label>
                              <input type="text" id="state-field" className="form-control" name="state_string" placeholder="New York" required defaultValue={catchObject.location_state}/>
                          </td>
                          <td>
                              <label htmlFor="statecode-field" className="form-label">State Code*</label>
                              <input type="text" id="statecode-field" className="form-control" name="state_code" placeholder="NY" required maxLength={2} defaultValue={catchObject.location_state_code}/>
                          </td>
                      </tr>
                  </div>
              
                  <div className="mb-3">
                      <label htmlFor="country-field" className="form-label">Catch Country*</label>
                      <select className="form-control" data-trigger id="country-field" required name="catch_country" defaultValue={catchObject.location_country}>
                          <option value="">Select Catch Country</option>
                          <option value="USA">United States</option>
                          <option value="CAN">Canada</option>
                      </select>
                  </div>
                  
                  {updateTagProgram &&
                  <div className="mb-3">
                      <label htmlFor="program-field" className="form-label">Tagging Program*</label>
                      <select className="form-control" data-trigger name="tag_program" id="program-field" required defaultValue={updateTagProgram.program_id}>
                          <option value="">Select Tag Program*</option>
                          {allTagPrograms.map(({ program_name, id }, index) => <option value={id} >{program_name}</option>)}
                      </select>
                  </div> 
                  }
      
                  <div className="mb-3">
                      <label htmlFor="tag-field" className="form-label">Tag Number*</label>
                      <input type="text" id="tag-field" name="tag_number" className="form-control" placeholder="Enter Tag Number*" maxLength={15} required defaultValue={catchObject.tag_number} />
                  </div>
      
                  <div className="mb-3">
                      <label htmlFor="species-field" className="form-label">Fish Species*</label>
                      <select className="form-control" data-trigger name="fish_species" id="species-field" required defaultValue={catchObject.fish_species.id}>
                          <option value="">Select Fish Species*</option>
                          {allSpecies.map(({ name, id }, index) => <option value={id} >{name}</option>)}
                      </select>
                  </div>   
                  
                  <div className="mb-3"><tr>
                      <td>
                      {catchObject.metric_system && <label htmlFor="length-field" className="form-label">Fish Length (CM)</label>}
                      {!catchObject.metric_system && <label htmlFor="length-field" className="form-label">Fish Length (IN)</label>}
                      <input type="number" id="length-field" className="form-control" placeholder="Enter Fish Length" defaultValue={catchObject.length} name="fish_length"/>
                      </td>
                      <td>
                      {catchObject.metric_system && <label htmlFor="weight-field" className="form-label">Fish Weight (KG)</label>}
                      {!catchObject.metric_system && <label htmlFor="weight-field" className="form-label">Fish Weight (LB)</label>}
                      <input type="number" id="weight-field" className="form-control" placeholder="Enter Fish Weight" defaultValue={catchObject.weight} name="fish_weight"/>
                      </td>
                  </tr></div>
                  
                  <div className="mb-3">
                      <label htmlFor="catchinfo-field" className="form-label">Catch Information</label>
                      <input type="text" id="catchinfo-field" className="form-control" placeholder="Catch Information" defaultValue={catchObject.info} name="catch_info"/>
                  </div>
      
                  <div className="mb-3"><tr>
                      <td>
                      <label htmlFor="status-field" className="form-label">Catch Type*</label>
                      <select className="form-control" data-trigger name="catch_type" id="status-field" required defaultValue={catchObject.recatch}>
                          <option value="">Select Catch Type*</option>
                          <option value="false">New Catch</option>
                          <option value="true">Re-Catch</option>
                      </select>
                      </td>
                      <td>
                      <label htmlFor="units-field" className="form-label">Unit System*</label>
                      <select className="form-control" data-trigger name="catch_units" id="units-field" required defaultValue={catchObject.metric_system}>
                          <option value="">Select Units System*</option>
                          <option value="true">Metric System</option>
                          <option value="false">Imperial System</option>
                      </select>
                      </td>
                  </tr>
                  </div>
                  
              </ModalBody>
              <ModalFooter>
                  <div className="hstack gap-2 justify-content-end">
                      <button type="button" className="btn btn-light" onClick={() => toggleEditModal() }>Close</button>
                      <button type="submit" className="btn btn-success" id="add-btn">Update</button>
                  </div>
              </ModalFooter>
          </form>
          }
          
          <ModalBody>
              <ThreeCircles
                visible={isUpdating}
                height="100"
                width="100"
                color="#4192C3"
                ariaLabel="three-circles-loading"
                wrapperStyle={{style:"margin: 0px auto"}}
                wrapperClass="justify-content-center"
                />
          </ModalBody>
      </Modal>
      
      {/* Delete Recatch Modal */}
      <Modal isOpen={deleteRecatchModal} toggle={() => { toggleDeleteRecatchModal(); }} className="modal zoomIn" id="deleteRecatchModal" centered >
      
          {!isDeletingRecatch &&
          <ModalBody>
              <div className="mt-2 text-center">
                          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                              <h4>Delete Re-Catch Data</h4>
                              <p className="text-muted mx-4 mb-0">Are you sure you want to delete this re-catch?</p>
                          </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                  <button type="button" className="btn w-sm btn-light" onClick={() => toggleDeleteRecatchModal()}>Cancel</button>
                  <button type="button" className="btn w-sm btn-danger" id="delete-record" onClick={deleteRecatch}>Delete</button>
              </div>
          </ModalBody>
          }
              
              
              <ModalBody>
                  <ThreeCircles
                    visible={isDeletingRecatch}
                    height="100"
                    width="100"
                    color="#ff3d60"
                    ariaLabel="three-circles-loading"
                    wrapperStyle={{style:"margin: 0px auto"}}
                    wrapperClass="justify-content-center"
                    />
              </ModalBody>
      </Modal>
      
      {/* Remove Modal */}
      <Modal isOpen={deleteCatchModal} toggle={() => { toggleDeleteCatchModal(); }} className="modal zoomIn" id="deleteCatchModal" centered >
      
          {!isDeletingCatch &&
          <ModalBody>
              <div className="mt-2 text-center">
                          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                              <h4>Delete Catch Data</h4><br/>
                              <h5 className="text-muted mx-4 mb-0">This will delete the catch, connected re-catches and the fish-tag it used!!!</h5><br/>
                              <p className="text-muted mx-4 mb-0">Are you sure you want to delete this catch?</p>
                          </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                  <button type="button" className="btn w-sm btn-light" onClick={() => toggleDeleteCatchModal()}>Cancel</button>
                  <button type="button" className="btn w-sm btn-danger" id="delete-record" onClick={deleteCatch}>Delete</button>
              </div>
          </ModalBody>
          }
              
              
              <ModalBody>
                  <ThreeCircles
                    visible={isDeletingCatch}
                    height="100"
                    width="100"
                    color="#ff3d60"
                    ariaLabel="three-circles-loading"
                    wrapperStyle={{style:"margin: 0px auto"}}
                    wrapperClass="justify-content-center"
                    />
              </ModalBody>
      </Modal>
      
      
      {/* Restore Recatch Modal */}
      <Modal isOpen={restoreRecatchModal} toggle={() => { toggleRestoreRecatchModal(); }} className="modal zoomIn" id="restoreRecatchModal" centered >
      
          {!isRestoringRecatch &&
          <ModalBody>
              <div className="mt-2 text-center">
                          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                              <h4>Restore Re-Catch Data</h4>
                              <p className="text-muted mx-4 mb-0">Do you want to restore this re-catch?</p>
                          </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                  <button type="button" className="btn w-sm btn-light" onClick={() => toggleDeleteRecatchModal()}>Cancel</button>
                  <button type="button" className="btn w-sm btn-success" id="delete-record" onClick={restoreRecatch}>Restore</button>
              </div>
          </ModalBody>
          }
              
              
              <ModalBody>
                  <ThreeCircles
                    visible={isRestoringRecatch}
                    height="100"
                    width="100"
                    color="#4192C3"
                    ariaLabel="three-circles-loading"
                    wrapperStyle={{style:"margin: 0px auto"}}
                    wrapperClass="justify-content-center"
                    />
              </ModalBody>
      </Modal>
      
      {/* Restore Modal */}
      <Modal isOpen={restoreCatchModal} toggle={() => { toggleRestoreCatchModal(); }} className="modal zoomIn" id="restoreCatchModal" centered >
      
          {!isRestoringCatch &&
          <ModalBody>
              <div className="mt-2 text-center">
                          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                              <h4>Restore Catch Data</h4><br/>
                              <h5 className="text-muted mx-4 mb-0">This will restore the catch, connected re-catches and the fish-tag it used!!!</h5><br/>
                              <p className="text-muted mx-4 mb-0">Are you sure you want to restore this catch?</p>
                          </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                  <button type="button" className="btn w-sm btn-light" onClick={() => toggleRestoreCatchModal()}>Cancel</button>
                  <button type="button" className="btn w-sm btn-success" id="delete-record" onClick={restoreCatch}>Restore</button>
              </div>
          </ModalBody>
          }
              
              
              <ModalBody>
                  <ThreeCircles
                    visible={isRestoringCatch}
                    height="100"
                    width="100"
                    color="#4192C3"
                    ariaLabel="three-circles-loading"
                    wrapperStyle={{style:"margin: 0px auto"}}
                    wrapperClass="justify-content-center"
                    />
              </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default CatchDetails;
